import { USER_LOGIN_REQUEST,USER_LOGIN_SUCCESS,USER_LOGIN_FAIL,GET_USERINFO_FAIL,GET_USERINFO_REQUEST,GET_USERINFO_SUCCESS, GET_USER_ORDERS_REQUEST, GET_USER_ORDERS_SUCCESS, GET_USER_ORDERS_FAIL } from "../Constants/userConstants"


export const userLoginReducer = (state = {}, action)=>{
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return {loading : true} 
        case USER_LOGIN_SUCCESS:
            return { loading : false, loginInfo:action.payload}  
        case USER_LOGIN_FAIL:
                return { loading : false, error:action.payload}      
        default:
            return state
    }
}


export const getUserInfoReducer = (state = {}, action)=>{
    switch (action.type) {
        case GET_USERINFO_REQUEST:
            return {loading : true} 
        case GET_USERINFO_SUCCESS:
            return { loading : false, userInfo:action.payload}  
        case GET_USERINFO_FAIL:
                return { loading : false, error:action.payload}      
        default:
            return state
    }
}

export const getUserOrdersReducer = (state = {}, action)=>{
    switch (action.type) {
        case GET_USER_ORDERS_REQUEST:
            return {loading : true} 
        case GET_USER_ORDERS_SUCCESS:
            return { loading : false, orders:action.payload}  
        case GET_USER_ORDERS_FAIL:
                return { loading : false, error:action.payload}      
        default:
            return state
    }
}