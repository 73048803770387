

export const GET_ALLBIKES_REQUEST = "GET_ALLBIKES_REQUEST"
export const GET_ALLBIKES_SUCCESS = "GET_ALLBIKES_SUCCESS"
export const GET_ALLBIKES_FAIL = "GET_ALLBIKES_FAIL"



export const GET_BIKE_BY_NAME_REQUEST = "GET_BIKE_BY_NAME_REQUEST"
export const GET_BIKE_BY_NAME_SUCCESS = "GET_BIKE_BY_NAME_SUCCESS"
export const GET_BIKE_BY_NAME_FAIL = "GET_BIKE_BY_NAME_FAIL"

export const GET_PRODUCT_BY_SUB_CATEGORY_REQUEST = "GET_PRODUCT_BY_SUB_CATEGORY_REQUEST"
export const GET_PRODUCT_BY_SUB_CATEGORY_SUCCESS = "GET_PRODUCT_BY_SUB_CATEGORY_SUCCESS"
export const GET_PRODUCT_BY_SUB_CATEGORY_FAIL = "GET_PRODUCT_BY_SUB_CATEGORY_FAIL"


export const GET_ALL_ACCESSORIES_REQUEST = "GET_ALL_ACCESSORIES_REQUEST"
export const GET_ALL_ACCESSORIES_SUCCESS = "GET_ALL_ACCESSORIES_SUCCESS"
export const GET_ALL_ACCESSORIES_FAIL = "GET_ALL_ACCESSORIES_FAIL"

