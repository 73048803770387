import {CREATE_TESTRIDE_APPOINTMENT_FAIL,CREATE_TESTRIDE_APPOINTMENT_REQUEST,CREATE_TESTRIDE_APPOINTMENT_SUCCESS} from '../Constants/testrideConstants'

export const bookTestrideReducer = (state = {}, action)=>{
    switch (action.type) {
        case CREATE_TESTRIDE_APPOINTMENT_REQUEST:
            return {loading : true} 
        case CREATE_TESTRIDE_APPOINTMENT_SUCCESS:
            return { loading : false, testride:action.payload}  
        case CREATE_TESTRIDE_APPOINTMENT_FAIL:
                return { loading : false, error:action.payload}      
        default:
            return state
    }
}
