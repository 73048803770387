export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'


export const GET_USERINFO_REQUEST = 'GET_USERINFO_REQUEST'
export const GET_USERINFO_SUCCESS = 'GET_USERINFO_SUCCESS'
export const GET_USERINFO_FAIL = 'GET_USERINFO_FAIL'



export const GET_USER_ORDERS_REQUEST = 'GET_USER_ORDERS_REQUEST'
export const GET_USER_ORDERS_SUCCESS = 'GET_USER_ORDERS_SUCCESS'
export const GET_USER_ORDERS_FAIL = 'GET_USER_ORDERS_FAIL'

