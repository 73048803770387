import axios from 'axios'
import {
	USER_LOGIN_FAIL,
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	GET_USERINFO_FAIL,
	GET_USERINFO_REQUEST,
	GET_USERINFO_SUCCESS,
	GET_USER_ORDERS_REQUEST,
	GET_USER_ORDERS_SUCCESS,
	GET_USER_ORDERS_FAIL,
} from '../Constants/userConstants'

export const loginUser =
	(socialId, name, email, loginType, profilePicture) => async (dispatch) => {
		try {
			dispatch({ type: USER_LOGIN_REQUEST })
			const { data } = await axios.post('/api/user/login', {
				socialId,
				name,
				email,
				loginType,
				profilePicture,
			})

			dispatch({
				type: USER_LOGIN_SUCCESS,
				payload: data,
			})
			localStorage.setItem('userInfo', JSON.stringify(data))
		} catch (error) {
			dispatch({
				type: USER_LOGIN_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getloginUserInfo = (id) => async (dispatch, getState) => {
	try {


		const {
			userLogin: { loginInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${loginInfo.tokken}`,
			},
		}

		dispatch({ type: GET_USERINFO_REQUEST })
		const { data } = await axios.post('/api/user/getuserinfo', { id }, config)

		dispatch({
			type: GET_USERINFO_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: GET_USERINFO_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}



export const getUserOrdersList = (id) => async (dispatch,getState) => {
	try {


		const {
			userLogin: { loginInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${loginInfo.tokken}`,
			},
		}

		dispatch({ type: GET_USER_ORDERS_REQUEST })
		const { data } = await axios.post('/api/user/orders', { id },config)

		dispatch({
			type: GET_USER_ORDERS_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: GET_USER_ORDERS_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
