export const CART_ADD_ITEM = 'CART_ADD_ITEM'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'
export const CART_SAVE_SHIPPING_ADDRESS = 'CART_SAVE_SHIPPING_ADDRESS'
export const CART_RESET = 'CART_RESET'




export const CONFIRM_CART_ITEMS = 'CONFIRM_CART_ITEMS'



export const CHECK_PROMOCODE_REQUEST = "CHECK_PROMOCODE_REQUEST"
export const CHECK_PROMOCODE_SUCCESS = "CHECK_PROMOCODE_SUCCESS"
export const CHECK_PROMOCODE_FAIL = "CHECK_PROMOCODE_FAIL"