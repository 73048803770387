import {GET_DEALERS_FAIL,GET_DEALERS_REQUEST,GET_DEALERS_SUCCESS} from '../Constants/dealerConstants'

export const allDealersReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_DEALERS_REQUEST:
			return { loading: true }
		case GET_DEALERS_SUCCESS:
			return { loading: false, dealers: action.payload }
		case GET_DEALERS_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}